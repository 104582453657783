<template>
  <div class="page">
    <div fluid class="container-fluid main">
      <Header />
      <div class="content" :class="{ scrolled: scrolled }" @scroll="handleScroll">
        <div class="container" v-if="!isIE">
          <div class="row">
            <div class="col-md-12 col-lg-4 primary">
              <img src="~@/assets/images/small-logo.png" width="30" height="30" class="logo" alt="Netstar" />
              <div class="heading">
                <div>WELCOME</div>
                <div class="last">
                  TO
                  <span>MyNetstar</span>
                </div>
              </div>
              <div class="spacer"></div>
              <div class="form-container">
                <Alert />
                <form method="post" @submit.prevent="handleSubmit">
                  <div class="form-group">
                    <input
                      type="text"
                      autocomplete="username"
                      v-model="username"
                      id="username"
                      name="username"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && !username }"
                    />
                    <label for="username">Email Address</label>
                    <div v-show="submitted && !username" class="invalid-feedback">Email address is required</div>
                  </div>
                  <div class="form-group">
                    <input
                      :type="passwordFieldType"
                      autocomplete="current-password"
                      v-model="password"
                      id="password"
                      name="password"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && !password }"
                    />
                    <label for="password">Password</label>
                    <div class="show-hide-container">
                      <a class="show-hide" @click="togglePasswordVisibility">{{ passwordFieldType === 'password' ? 'SHOW' : 'HIDE' }}</a>
                    </div>
                    <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
                  </div>
                  <div class="form-group buttons">
                    <button class="btn btn-submit" :disabled="status.loggingIn">
                      <span v-show="!status.loggingIn">LOG IN</span>
                      <div class="d-flex justify-content-center" v-if="status.loggingIn">
                        <Spinner />
                        <span class="ml-2" v-if="isSlowToLogin">Still busy...</span>
                      </div>
                    </button>
                    <router-link to="reset-password" class="text-link text-right">Forgot Username / Password?</router-link>
                  </div>
                </form>
              </div>
              <div class="footer">
                <div class="cta">
                  <div class="text">
                    <div class="subheading">NETSTAR CUSTOMERS</div>
                    <router-link to="register" class="text-link"><span>Register</span> here</router-link>
                  </div>
                  <div class="text">
                    <div class="subheading">BUY NETSTAR NOW</div>
                    <a href="https://www.netstar.co.za/mynetstar/" target="_blank" class="text-link"><span>See our packages</span> here</a>
                  </div>
                  <button class="rounded-button" @click="promptInstall" v-if="canInstallApp && !$isMobile">INSTALL APP</button>
                </div>
                <div class="mobile-app-info" v-if="$isMobile">
                  <p class="text-content">For a better mobile experience, please use the <strong>MyNetstar App</strong></p>
                  <div class="app-buttons">
                    <a href="https://apps.apple.com/za/app/mynetstar/id1629057326" target="_blank">
                      <img src="~@/assets/images/apple-app-store-mobile.png" alt="Download on the App Store" class="app-store-image">
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=za.co.netstar.my.twa&hl=en&gl=US " target="_blank">
                      <img src="~@/assets/images/google-play-store-mobile.png" alt="Get it on Google Play" class="google-play-image">
                    </a>
                  </div>
                </div>
                <div class="info-box mb-md-4">
                  <div class="small-italic-text">
                    <p>Registered as a security service provider by the Private Security Industry Regulatory Authority, registration number: 1205544.</p>
                    <p class="mb-0">
                      Netstar is a subsidiary of Altron and respects your privacy - please refer to the
                      <a href="https://www.altron.com/privacy-policy/" target="_blank"><u>Altron Privacy Policy</u></a>
                      for details on how we collect and use your personal information, with whom we share it, and your choices and rights in relation to your
                      personal information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 column-spacer"></div>
            <div class="col-12 col-md-6 col-lg-4 secondary">
              <div class="spacer"></div>
              <div class="heading">Track your personal<br />vehicle or fleet from<br />anywhere with <span>MyNetstar</span>.</div>
              <a href="https://buy.netstar.co.za/personal/" target="_blank" class="btn btn-outline">BUY NOW: Personal Vehicle</a>
              <a href="https://buyfleet.netstar.co.za/" target="_blank" class="btn btn-outline">BUY NOW: Business Fleet</a>
            </div>
          </div>
        </div>
        <IncompatibleBrowser v-else />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/pages.scss";

.page {
  .content {
    > .container {
      .secondary {
        .heading {
          margin-bottom: 20px;
        }

        .btn-outline {
          min-width: 210px;
          margin: 0 0 15px 22px;
        }
      }
    }
    .show-hide-container {
      position: relative;

      a {
        text-decoration: none;
      }

      .show-hide {
        bottom: 24px;
        position: absolute;
        right: 5px;
        z-index: 1;
        color: $brand-color-2;
        font-size: 12px;
        font-weight: 600;

      }
    }

    .mobile-app-info {
      background-color: $brand-color-2;
      margin-bottom: 30px;
      padding: 20px;

      .text-content {
        color: white;
        font-size: 14px;
        text-align: center;
      }

      .app-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
          display: flex;
          justify-content: center;
          img {
            display: block;
            height: auto;
            max-width: 100%;
          }
        }

        .app-store-image {
          width: 116px;
        }

        .google-play-image {
          width: 130px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .mobile-app-info {
    padding: 25px 35px;

    .text-content {
      padding: 0 40px;
    }

    .app-buttons {
      padding-left: 35px;
      padding-right: 35px;

    }
  }
}
</style>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { modalService, publicApiService, tooltipService } from "@/services";
import Header from "@/pages/components/Header";
import IncompatibleBrowser from "@/components/IncompatibleBrowser";
import { helper } from "@/helper";

export default {
  components: {
    Header,
    IncompatibleBrowser,
  },
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      scrolled: false,
      isSlowToLogin: false,
      passwordFieldType: 'password',
    };
  },
  computed: {
    ...mapState("account", ["status"]),
    ...mapState(["alert"]),
    ...mapState("pwa", ["deferredPromptForInstall", "canInstallApp"]),
    isIE() {
      return /*@cc_on!@*/ false || !!document.documentMode;
    },
  },
  created() {
    publicApiService
      .getSystemNotification()
      .then((message) => {
        if (message) {
          modalService.showSystemNotificationDialog(message);
        }
      })
      .catch((error) => {});
  },
  methods: {
    ...mapActions("account", ["login"]),
    ...mapMutations("pwa", ["updateDeferredPromptForInstall"]),
    promptInstall() {
      if (this.deferredPromptForInstall) {
        this.deferredPromptForInstall.prompt();
        this.deferredPromptForInstall.userChoice.then((choiceResult) => {
          // if (choiceResult.outcome === "accepted") {
          //   console.log("User accepted the install prompt");
          // } else {
          //   console.log("User dismissed the install prompt");
          // }
          this.updateDeferredPromptForInstall(null);
        });
      } else if (this.$isIOS) {
        tooltipService.showTooltip("install-app");
      }
    },
    handleScroll(event) {
      this.scrolled = event.target.scrollTop > 5;
    },
    handleSubmit(e) {
      this.submitted = true;
      this.isSlowToLogin = false;
      const { username, password } = this;
      if (username && password) {
        setTimeout(() => {
          this.isSlowToLogin = true;
        }, 4000)
        const origin = helper.getOriginInfo();
        this.login({ username, password, origin });
      }
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
  },
};
</script>
